<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Project</h3>
          <div class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <!-- Tabla-->
              <div class="">
                <table id="project-table" class="table table-striped  responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Project</span>
                      </th>
                      <th scope="col" class="thead-bottom">
                        <span class="text-blue">Status</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special" v-if="ListProjects.status != 'false'">
                    <tr class="text-start" v-for="(obj, key, index) in ListProjects.list" :key="index">
                      <td class="text-start">{{obj.name}}</td>
                      <td class="text-start">
                        <p v-if="obj.status == '1'" class="special-badget-status">Active</p>
                        <p v-if="obj.status == '0'" class="special-badget-status-inactive">Inactive</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import $ from 'jquery';
  
  export default 
  {
    name: 'Project',
    data: () => (
    {
      ProjectPost: 
      {
        name: "",
        status: "",
        message: "",
        status_post: ""
      },
      ListProjects: 
      {
        status: "false",
        list: {}
      }
    }),
    methods: 
    {
      GetProject() 
      {
        Api.get('/projects-members/projects').then((result) => 
        {
          this.ListProjects.status = result.data.status
          
          if (this.ListProjects.status == "true") 
          {
            this.ListProjects.list = result.data['member_projects'];
            this.$nextTick(() => 
            {
              this.InitDatatable()
            })
          }
        })
      },
      InitDatatable() 
      {
        var table = $('#project-table').DataTable(
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      RefreshDatatable() 
      {
        Api.post("/project/list", '').then((result) => 
        {
          this.ListProjects.status = result.data.status
          
          if (this.ListProjects.status == "true") 
          {
            this.ListProjects.list = result.data.project;
          }
        })
      }
    },
    mounted() 
    {
      this.GetProject()
    }
  }
</script>

<style scoped></style>
